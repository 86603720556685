
// Libraries
import * as React from 'react'

// Services
import TransactionalEmailsService from '../../../services/transactionalEmails'

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminTransactionalEmailsTableHeader from '../../../components/admin/transactionalEmails/adminTransactionalEmailsTableHeader'
import AdminTransactionalEmailsTable from '../../../components/admin/transactionalEmails/adminTransactionalEmailsTable'
import AdminTransactionalEmailsEdit from '../../../components/admin/transactionalEmails/adminTransactionalEmailsEdit'
import {faPaperPlane} from '@fortawesome/pro-solid-svg-icons'

// Context
import NotificationContext from '../../../contexts/notification'

class AdminTransactionalEmailsIndexPage extends React.Component {
	state = {
		items: [],
		emptyMessage: 'Retrieving emails ...',
		selectedItem: {},
		modalEditShow: false,
	}

	static contextType = NotificationContext

	render() {
		const {activeFilters, items, selectedItem, emptyMessage, modalEditShow} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-table page--admin-table-content-management nav-blue-half">
				<Seo title="Email Content Management" />
				<div className="admin-table__wrap">
					<AdminTransactionalEmailsTableHeader
						titleText="Email Content Management"
						titleIcon={faPaperPlane}
						handleConfirmBuild={this.handleConfirmBuild} />

					<AdminTransactionalEmailsTable
						activeFilters={activeFilters}
						items={items}
						emptyMessage={emptyMessage}
						handleOpenEdit={this.handleOpenEdit} />

					{modalEditShow && <AdminTransactionalEmailsEdit
						item={selectedItem}
						handleClose={() => this.setState({
							modalEditShow: false
						})}
						handleEditComplete={this.handleEditComplete} />}
				</div>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		const {addNotification} = this.context

		Promise.all([
			this.getItems({}, false)
				.catch(() => addNotification('There was an error fetching the emails.', 'error'))
		]).then(() => {
			this.setState({
				activeFilters: {},
				emptyMessage: "No emails found, try adjusting your filters"
			})
		})
	}

	getItems = (queryParams) => {
		const service = new TransactionalEmailsService()
		return service.list(queryParams).then( (response) => {
			this.setState({
				items: response.data
			})
		}).catch(err => console.error(err))
	}

	handleOpenEdit = (itemId) => {
		const service = new TransactionalEmailsService()
		return service.get(itemId)
			.then( (response) => {
				this.updateStateSelectedItem(response.data)
				this.setState({
					modalEditShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleEditComplete = (item) => {
		this.updateStateSelectedItem(item)
		this.updateStateItems(item)
		this.setState({
			modalEditShow: false
		})
	}

	updateStateSelectedItem = (item) => {
		this.setState(prevState => ({
			selectedItem: {...prevState.selectedItem, ...item}
		}))
	}

	updateStateItems = (item, remove = false) => {
		const {items} = this.state

		items.forEach((existingItem, key) => {
			if (existingItem.id === item.id) {
				if (remove) {
					items.splice(key, 1)
				}
				else {
					items[key] = {...existingItem, ...item}
				}
			}
		})

		this.setState({items})
	}
}

export default AdminTransactionalEmailsIndexPage

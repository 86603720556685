
// Services
import ApiService from './api'

const apiService = new ApiService()

export default class TransactionalEmailsService {
	async list(queryParams = {}) {
		return await apiService.get(`transactional-emails`, {}, queryParams)
	}

	async get(idOrSlug = null) {
		return await apiService.get(`transactional-emails/${idOrSlug}`)
	}

	async put(id, formData = {}) {
		return await apiService.put(`transactional-emails/${id}`, {body: formData}, true)
	}

	formData(inputValues) {
		const formData = new FormData()
		Object.keys(inputValues).forEach(key => {
			if (typeof inputValues[key] === 'object' && inputValues[key].value) {
				// deal with react-select object values
				formData.set(key, inputValues[key]['value'])
			}
			else if (key !== 'tags') {
				formData.set(key, inputValues[key])
			}
		})

		return formData
	}
}

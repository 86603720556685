
// Libraries
import * as React from 'react'

// Services
import TransactionalEmailsService from '../../../services/transactionalEmails'

// Context
import NotificationContext from '../../../contexts/notification'

// Components
import Modal from '../../modal'
import AdminViewRow from '../adminViewRow'
import InputFactory from '../../inputFactory'
import Button from '../../button'
import ReactTooltip from 'react-tooltip'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/pro-solid-svg-icons";

export default class AdminTransactionalEmailsEdit extends React.Component {
	state = {
		initialValues: {},
		inputValues: {},
		inputErrors: {},
		isSubmitting: false,
	}

	static contextType = NotificationContext

	render() {
		const {item, handleClose} = this.props
		const {initialValues, inputValues, inputErrors, isSubmitting} = this.state

		const imageHeaderOptions = [
			{
				value: 'default',
				label: 'default'
			},
			{
				value: 'ballot-submitted',
				label: 'ballot-submitted'
			},
			{
				value: 'ballot-successful',
				label: 'ballot-successful'
			},
			{
				value: 'ballot-unsuccessful',
				label: 'ballot-unsuccessful'
			},
			{
				value: 'event-followup',
				label: 'event-followup'
			},
			{
				value: 'generic',
				label: 'generic'
			},
			{
				value: 'incomplete-registration',
				label: 'incomplete-registration'
			},
			{
				value: 'new-event',
				label: 'new-event'
			},
			{
				value: 'new-venue',
				label: 'new-venue'
			},
			{
				value: 'profile-banned',
				label: 'profile-banned'
			},
			{
				value: 'profile-unbanned',
				label: 'profile-unbanned'
			},
			{
				value: 'reengage',
				label: 'reengage'
			},
			{
				value: 'refunded',
				label: 'refunded'
			},
			{
				value: 'reset-password',
				label: 'reset-password'
			},
			{
				value: 'verify-email',
				label: 'verify-email'
			},
			{
				value: 'welcome',
				label: 'welcome'
			},
		]

		return <Modal handleClose={handleClose} modalRight adminForm>
			<div className="admin-form admin-form--transactional-emails">
				<div className="admin-form__header">
					<div className="column">
						<h3>Edit: {item.name}</h3>
					</div>
					<div className="column">
					</div>
				</div>
				<div className="admin-form__form">
					<form onSubmit={this.handleSubmit}>
						<div className="admin-form__field-group">
							<AdminViewRow
								label="Header"
								value={<InputFactory
									type="select"
									name="image_header"
									empty={false}
									value={inputValues?.image_header}
									error={inputErrors?.image_header}
									onChange={this.handleInputChange}
									options={imageHeaderOptions}
								/>} />
							<AdminViewRow
								label={<>
									Variables
									<FontAwesomeIcon icon={faInfoCircle} data-tip data-for={`tooltip-variables`}
													 className='tooltip-icon' multiline={true} html={true} />
								</>}
								value={item.variables ?? 'None'}
								noinput />
							<ReactTooltip id={`tooltip-variables`} place="right" backgroundColor="#005EB8" textColor="#fff">
								<span>
									To use a variable, include it within your content as follows:<br /><br />
									{ "{{ $variableName }}" }<br /><br />
									<small>Note: The double curly braces and space either side of the variable name are required for the variable to work.</small>
								</span>
							</ReactTooltip>
							<AdminViewRow
								label="Content"
								value={<InputFactory
									type="tinymce"
									name="content"
									initialValue={initialValues?.content}
									value={inputValues?.content}
									error={inputErrors?.content}
									onChange={this.handleInputChange}
									convertUrls={false}
								/>}
							/>
						</div>

						<div className="admin-form__submit-wrap">
							<Button type="button" onClick={handleClose} colorEndeavour hollow>Discard Changes</Button>
							<Button type="submit" isLoading={isSubmitting} colorEndeavour>Save Changes</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	}

	componentDidMount() {
		const {item} = this.props
		const {inputValues} = this.state
		const initialValues = {...item}

		inputValues.image_header = {label: item.image_header, value: item.image_header}
		inputValues.content = item.content

		this.setState({ inputValues, initialValues })
	}

	handleInputChange = (value, name, scope) => {
		const {inputValues} = this.state

		let nameSplit = null
		if (name.includes('[')) {
			nameSplit = name.replaceAll(']', '').split('[')
		}

		if (value !== undefined) {
			if (nameSplit) {
				if (nameSplit.length === 4) {
					inputValues[nameSplit[0]][nameSplit[1]][nameSplit[2]][nameSplit[3]] = value
				}
				else if (nameSplit.length === 3) {
					inputValues[nameSplit[0]][nameSplit[1]][nameSplit[2]] = value
				}
				else if (nameSplit.length === 2) {
					inputValues[nameSplit[0]][nameSplit[1]] = value
				}
			}
			else if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			if (nameSplit) {
				if (nameSplit.length === 4) {
					delete inputValues[nameSplit[0]][nameSplit[1]][nameSplit[2]][nameSplit[3]]
				}
				else if (nameSplit.length === 3) {
					delete inputValues[nameSplit[0]][nameSplit[1]][nameSplit[2]]
				}
				else if (nameSplit.length === 2) {
					delete inputValues[nameSplit[0]][nameSplit[1]]
				}
			}
			else if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		this.setState({ inputValues })
	}

	handleSubmit = async (submitEvent = null) => {
		submitEvent.preventDefault()
		this.setState({
			isSubmitting: true
		})

		const {item, handleEditComplete} = this.props
		const {inputValues} = this.state
		const service = new TransactionalEmailsService()
		const {addNotification} = this.context

		const formData = service.formData(inputValues)
		await service.put(item.id, formData)
			.then(async (response) => {
				if (response.success) {
					addNotification('Page Updated', 'success')
					handleEditComplete(response.data)
				}
				else {
					const inputErrors = response.errors
					this.setState({
						inputErrors: inputErrors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

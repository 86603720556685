
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

class AdminViewRow extends React.Component {
	render() {
		const {label, value} = this.props
		let classNames = Classnames([
			'admin-view__view-row',
			{
				'admin-view__view-row--stacked': this.props.stacked,
			},
			{
				'admin-view__view-row--disabled': this.props.disabled,
			},
			{
				'admin-view__view-row--noinput': this.props.noinput,
			},
		])

		return <div className={classNames}>
			<div className="column column--label">
				<p><b>{label}</b></p>
			</div>
			<div className="column column--value">
				<p>{value}</p>
			</div>
		</div>
	}
}

export default AdminViewRow
